<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS OBJETIVOS
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de objetivos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('002-PRO-OBJ','c') == 1" cols="4" class="my-2">
                            <b-button block :to="{name: 'Gestión de objetivos'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo objetivo
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Objetivos registrados</span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaObjetivos" :fields="campoObjetivos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('002-PRO-OBJ','u') == 1"   :to="{name: 'Gestión de objetivos', params: {id: param.item.idObjetivo}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('002-PRO-OBJ','d') == 1" @click="eliminarObjetivo(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'" class="mr-1 mb-1">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(valorPartidax)="data">
                                    <template v-if="data.item.valorPartiday == 0">
                                        {{data.item.valorPartidax}}
                                    </template>
                                    <template v-else-if="data.item.valorPartiday != 0">
                                        {{data.item.valorPartidax}} / {{data.item.valorPartiday}}
                                    </template>
                                </template>
                                <template #cell(valorEsperadox)="data">
                                    <template v-if="data.item.valorReal == 1">
                                        {{data.item.valorEsperadox}}
                                    </template>
                                    <template v-else-if="data.item.valorReal == 2">
                                        {{data.item.valorEsperadox}} / {{data.item.valorEsperadoy}}
                                    </template>
                                </template>
                                <template #cell(condicion)="data">
                                    <span v-if="data.item.condicion == 1">=</span>
                                    <span v-else-if="data.item.condicion == 2">&gt;=</span>
                                    <span v-else-if="data.item.condicion == 3">&gt;</span>
                                    <span v-else-if="data.item.condicion == 4">&lt;=</span>
                                    <span v-else-if="data.item.condicion == 5">&lt;</span>
                                </template>
                                <template #cell(conseguido)="data">
                                    <b-icon variant="success" v-if="data.value==2" icon="check-circle-fill"></b-icon>
                                    <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                </template>
                                <template #cell(nombre)="param">
                                    {{param.item.nombre | truncate(30)}}
                                </template>
                                <template #cell(definicion)="param">
                                    {{param.item.definicion | truncate(30)}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import {
    CChartPie
} from '@coreui/vue-chartjs'

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            labelEstadistica: ['Conseguidos', 'Por alcanzar'],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            idCliente: '',

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoObjetivos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "definicion",
                    label: "Definición",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Proceso asociado",
                    class: "text-center",
                },
                {
                    key: "fechaInicio",
                    label: "Fecha inicio",
                    class: "text-center",
                },
                {
                    key: "fechaLimite",
                    label: "Fecha límite",
                    class: "text-center",
                },
                {
                    key: "valorPartidax",
                    label: "Valor de partida",
                    class: "text-center",
                },
                {
                    key: "condicion",
                    label: "Operador",
                    class: "text-center",
                },
                {
                    key: "valorEsperadox",
                    label: "Valor esperado",
                    class: "text-center",
                },
                {
                    key: "conseguido",
                    label: "¿Conseguido?",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaObjetivos: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        resetModalNuevaEtiqueta() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosEtiqueta.nombre = '',
                this.datosEtiqueta.color = null
        },
        listarObjetivos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-objetivos", {
                        params: {
                            idCliente: me.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaObjetivos = response.data
                    me.totalRows = me.listaObjetivos.length
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarObjetivo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el objetivo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-objetivo", {
                                idObjetivo: param.item.idObjetivo
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarObjetivos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#428bca94',
                    '#b02b2c9e',
                ],
                data: [this.listaObjetivos.filter(x => x.conseguido == 2).length, this.listaObjetivos.filter(x => x.conseguido == 1).length]
            }]
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.idCliente = user.uidClient;
            this.listarObjetivos();
        }
    }

}
</script>
